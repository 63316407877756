import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { DateUtils } from 'ts/commons/DateUtils';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { PathUtils } from 'ts/commons/PathUtils';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import type { DeltaParametersFormData } from 'ts/perspectives/delta/parameters/ParameterDeltaView';
import { EType } from 'typedefs/EType';

/** The parameters of the delta perspective. */
export class DeltaParameters {
	/**
	 * The timespan in which we consider a concrete timestamp equal to HEAD. So if the timestamp is no older than one
	 * minute we show the already cached data.
	 */
	private static readonly HEAD_STALE_TIME_MILLISECONDS = 60000;

	/** The parameter name for the start commit used for delta calculation. The corresponding field is {@link fromCommit} */
	public static readonly START_COMMIT_PARAMETER_NAME = 'from';

	/** The parameter name for the end commit used for delta calculation. The corresponding field is {@link toCommit} */
	public static readonly END_COMMIT_PARAMETER_NAME = 'to';

	/** The parameter name for the group in the url. The corresponding field is {@link groupName} */
	public static readonly GROUP_PARAMETER_NAME = 'group';

	/** The parameter name for the delta type in the url. The corresponding field is {@link isSpecItemDelta}. */
	public static readonly DELTA_TYPE_PARAMETER_NAME = 'isSpecItemDelta';

	/**
	 * Name of the parameter stating that we show a merge-request delta instead of a time-range delta. This parameter
	 * name is used in the merge-requests in gitlab, therefore it can't be easily changed.
	 */
	public static SHOW_MERGE_REQUEST_PARAMETER_NAME = 'showMergeFindings';

	/**
	 * Refers to the commit which is the basis for the delta analysis. For the linear history (isMergeDelta === false)
	 * this is the older commit, for the merge delta (isMergeDelta === true) this is the source commit i.e. commit on a
	 * feature branch that is about to be merged into the target branch.
	 */
	public fromCommit: UnresolvedCommitDescriptor;

	/**
	 * Refers to the commit which is the target for the delta analysis. For the linear history (isMergeDelta === false)
	 * this is the newer commit, for the merge delta (isMergeDelta === true) this is the target commit i.e. commit on
	 * the default branch into which the source branch is about to be merged.
	 */
	public toCommit: UnresolvedCommitDescriptor;

	/** The selected uniform path to show the delta for. */
	public path: ProjectAndUniformPath;

	/**
	 * The user group which is the target group (team) for the delta analysis. This allows filtering for findings, which
	 * are relevant for one group/team, which can be useful, if multiple teams are working on the same codebase.
	 */
	public groupName?: string;

	/** @param isMergeDelta Whether the parameters refer to a merge delta scenario or a linear history delta. */
	public constructor(public readonly isMergeDelta: boolean) {
		this.fromCommit = UnresolvedCommitDescriptor.createLatestOnDefaultBranch();
		this.toCommit = UnresolvedCommitDescriptor.createLatestOnDefaultBranch();
		// We use EMPTY as a placeholder here to allow making the field non-nullable
		// It will get properly initialized in #initFromNavigationHash
		this.path = ProjectAndUniformPath.EMPTY;
	}

	/** Setter method for all parameter fields. */
	public setParameters(parameters: DeltaParametersFormData) {
		this.fromCommit = parameters.startDate;
		this.fromCommit.branchName = parameters.startBranch;
		this.toCommit = parameters.endDate;
		this.toCommit.branchName = parameters.endBranch;
		this.path = parameters.path;
		this.groupName = parameters.groupName;
	}

	/** Getter method for all parameter fields. */
	public getParametersForForm(): DeltaParametersFormData {
		return {
			startDate: this.fromCommit,
			endDate: this.toCommit,
			path: this.path,
			groupName: this.groupName,
			startBranch: this.fromCommit.branchName,
			endBranch: this.toCommit.branchName,
			isSpecItemDelta: this.isSpecItemDelta(),
			isMergeDelta: this.isMergeDelta
		};
	}

	/**
	 * Initializes the parameters when the delta view is loaded for the first time in the perspective lifecycle. We
	 * cannot perform this in the constructor as the project might not have been set at that point.
	 */
	public initFromNavigationHash(navigationHash: NavigationHash): void {
		if (this.path !== ProjectAndUniformPath.EMPTY) {
			// Already initialized
			return;
		}
		this.setPathFromNavigationHash(navigationHash);
		if (DeltaParameters.getShowMergeRequestParameterValue() === this.isMergeDelta) {
			this.setCommitsFromNavigationHash(navigationHash);
		}
		this.setGroupFromNavigationHash(navigationHash);
		this.setDeltaTypeFromNavigationHash(navigationHash);
	}

	/**
	 * Sets the parameters path, start commit, end commit and group according to the values included in the given
	 * navigation hash. If hash doesn't include start and end commit values, the default values are used instead.
	 */
	public setParametersFromNavigationHash(navigationHash: NavigationHash): void {
		this.setCommitsFromNavigationHash(navigationHash);
		this.setPathFromNavigationHash(navigationHash);
		this.setGroupFromNavigationHash(navigationHash);
		this.setDeltaTypeFromNavigationHash(navigationHash);
	}

	/**
	 * Sets the parameters start commit and end commit according to the values included in the given navigation hash. If
	 * hash doesn't include start and end commit values, the default values are used instead.
	 */
	private setCommitsFromNavigationHash(navigationHash: NavigationHash): void {
		this.fromCommit =
			navigationHash.getCommitParameter(DeltaParameters.START_COMMIT_PARAMETER_NAME) ??
			UnresolvedCommitDescriptor.createLatestOnDefaultBranch();
		this.toCommit =
			navigationHash.getCommitParameter(DeltaParameters.END_COMMIT_PARAMETER_NAME) ??
			UnresolvedCommitDescriptor.createLatestOnDefaultBranch();
	}

	/** Sets the parameters path according to the values included in the given navigation hash. */
	public setPathFromNavigationHash(navigationHash: NavigationHash): void {
		this.path = navigationHash.getProjectAndPath();
	}

	/** Reads the group from the navigation hash. */
	private setGroupFromNavigationHash(navigationHash: NavigationHash) {
		this.groupName = navigationHash.getString(DeltaParameters.GROUP_PARAMETER_NAME) ?? undefined;
	}

	/** Reads the delta type from the navigation hash. */
	private setDeltaTypeFromNavigationHash(navigationHash: NavigationHash) {
		const delta = navigationHash.getBoolean(DeltaParameters.DELTA_TYPE_PARAMETER_NAME);
		if (delta) {
			this.path = ProjectAndUniformPath.of(this.path.getProject(), EType.SPEC_ITEM.prefix);
		}
	}

	/**
	 * Resolves the artificial latest on default branch commit to the concrete default branch of the project and to a
	 * concrete timestamp.
	 */
	public ensurePinned(defaultBranch: string, recentBranches: string[]): void {
		if (this.fromCommit.isLatestOnDefaultBranch()) {
			if (this.isMergeDelta) {
				// For the merge scenario pick any other recently used branch, but not the default branch if possible
				const initialSourceBranch = recentBranches.find(branch => branch !== defaultBranch) ?? defaultBranch;
				this.fromCommit = new UnresolvedCommitDescriptor(Date.now(), initialSourceBranch);
			} else {
				this.fromCommit = new UnresolvedCommitDescriptor(DateUtils.getTimestampForDays(1), defaultBranch);
			}
		}
		this.fromCommit = UnresolvedCommitDescriptor.toPinnedTimestampCommitDescriptor(this.fromCommit, defaultBranch);
		this.toCommit = UnresolvedCommitDescriptor.toPinnedTimestampCommitDescriptor(this.toCommit, defaultBranch);
	}

	/** Returns whether the current parameter values match the path and commits given in the navigation hash. */
	public matchesNavigationHash(navigationHash: NavigationHash): boolean {
		const path = navigationHash.getProjectAndPath();
		if (this.path.toString() !== path.toString()) {
			return false;
		}
		const fromCommit = navigationHash.getCommitParameter(DeltaParameters.START_COMMIT_PARAMETER_NAME);
		if (fromCommit === null || !DeltaParameters.areCommitsEqual(this.fromCommit, fromCommit)) {
			return false;
		}
		const toCommit = navigationHash.getCommitParameter(DeltaParameters.END_COMMIT_PARAMETER_NAME);
		if (toCommit === null || !DeltaParameters.areCommitsEqual(this.toCommit, toCommit)) {
			return false;
		}
		const groupName = navigationHash.getString(DeltaParameters.GROUP_PARAMETER_NAME) ?? undefined;
		if (this.groupName !== groupName) {
			return false;
		}
		const isSpecItemDelta = navigationHash.getBoolean(DeltaParameters.DELTA_TYPE_PARAMETER_NAME);
		if (this.isSpecItemDelta() !== isSpecItemDelta) {
			return false;
		}
		return DeltaParameters.getShowMergeRequestParameterValue() === this.isMergeDelta;
	}

	/**
	 * API calls take a commit as parameter to use as a History Access Option. The right one differs based on whether
	 * {@link DeltaParameters#isMergeDelta}. If yes, the {@link DeltaParameters#fromCommit} should be used, as that is
	 * then the furthest end of the branch. If no, the {@link DeltaParameters#toCommit} should be used.
	 */
	public historyAccessOptionDeltaEnd(): UnresolvedCommitDescriptor {
		return this.isMergeDelta ? this.fromCommit : this.toCommit;
	}

	/**
	 * API calls take a commit as parameter to use as a History Access Option. Use this one for the post-delta future.
	 * If {@link DeltaParameters#isMergeDelta}, this subsumes {@link DeltaParameters#historyAccessOptionPostDelta()}.
	 */
	public historyAccessOptionPostDelta(): UnresolvedCommitDescriptor | null {
		return UnresolvedCommitDescriptor.latestOnBranch(this.toCommit.branchName);
	}

	/**
	 * Returns true if both commits are equal. The method considers the HEAD commit to be equal to a concrete
	 * timestamped commit if the timestamp is not older than one minute. Treating them as unequal would immediately
	 * invalidate the date cache for delta queries against HEAD.
	 */
	private static areCommitsEqual(commit1: UnresolvedCommitDescriptor, commit2: UnresolvedCommitDescriptor): boolean {
		if (commit1.getBranchName() !== commit2.getBranchName()) {
			return false;
		}
		if (commit1.getTimestamp() === commit2.getTimestamp()) {
			return true;
		}
		const oldestAcceptableTimestampForHead = Date.now() - DeltaParameters.HEAD_STALE_TIME_MILLISECONDS;
		if (commit1.isLatestRevision() && commit2.getTimestamp()! > oldestAcceptableTimestampForHead) {
			return true;
		}
		return commit2.isLatestRevision() && commit1.getTimestamp()! > oldestAcceptableTimestampForHead;
	}

	/** Returns the value of the showMergeRequest parameter in the current navigation hash. */
	public static getShowMergeRequestParameterValue(): boolean {
		return NavigationHash.getCurrent().getBoolean(DeltaParameters.SHOW_MERGE_REQUEST_PARAMETER_NAME);
	}

	/** Returns whether the currently selected delta type is the delta for spec items. */
	public isSpecItemDelta(): boolean {
		return PathUtils.isSpecItemPath(this.path.getPath());
	}
}
