import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import { DeltaParameters } from 'ts/perspectives/delta/commons/DeltaParameters';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';

/** Collection of all delta subview descriptors. */
export const EDeltaView = {
	PARAMETER: {
		name: 'Delta Parameters',
		anchor: 'input',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./parameters/ParameterDeltaView'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	FILE: {
		name: 'Changed Files',
		anchor: 'files',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./files/FileDeltaView'),
		canBeAccessed: (
			perspectiveContext: PerspectiveContext,
			serviceClient: TeamscaleServiceClient,
			hash: NavigationHash
		) => canShowDeltaViews(perspectiveContext, serviceClient, hash, 'Changed Files'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	REPOSITORY: {
		name: 'Repository Changes',
		anchor: 'changes',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./repository/RepositoryDeltaView'),
		canBeAccessed: (
			perspectiveContext: PerspectiveContext,
			serviceClient: TeamscaleServiceClient,
			hash: NavigationHash
		) => canShowDeltaViews(perspectiveContext, serviceClient, hash, 'Repository Changes'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	CHANGE_TREEMAP: {
		name: 'Change Treemap',
		anchor: 'treemap',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./change_treemap/ChangeTreemapDeltaView'),
		canBeAccessed: (
			perspectiveContext: PerspectiveContext,
			serviceClient: TeamscaleServiceClient,
			hash: NavigationHash
		) => canShowDeltaViews(perspectiveContext, serviceClient, hash, 'Change Treemap'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	TEST_GAP_TREEMAP: {
		name: 'Test Gap Treemap',
		anchor: 'test-gap',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./test_gaps/TestGapDeltaView'),
		canBeAccessed: (
			perspectiveContext: PerspectiveContext,
			serviceClient: TeamscaleServiceClient,
			hash: NavigationHash
		) => canShowDeltaViews(perspectiveContext, serviceClient, hash, 'Test Gap Treemap'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	METRICS: {
		name: 'Metric Changes',
		anchor: 'metrics',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./metrics/MetricsDeltaView'),
		canBeAccessed: (
			perspectiveContext: PerspectiveContext,
			serviceClient: TeamscaleServiceClient,
			hash: NavigationHash
		) => canShowDeltaViews(perspectiveContext, serviceClient, hash, 'Metric Changes'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	FINDING: {
		name: 'Findings Churn',
		anchor: 'findings',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./findings/FindingDeltaView'),
		canBeAccessed: (
			perspectiveContext: PerspectiveContext,
			serviceClient: TeamscaleServiceClient,
			hash: NavigationHash
		) => canShowDeltaViews(perspectiveContext, serviceClient, hash, 'Findings Churn'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	TESTS: {
		name: 'Impacted Tests',
		anchor: 'tests',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./impacted_tests/ImpactedTestsDeltaView'),
		canBeAccessed: (
			perspectiveContext: PerspectiveContext,
			serviceClient: TeamscaleServiceClient,
			hash: NavigationHash
		) => canShowDeltaViews(perspectiveContext, serviceClient, hash, 'Impacted Tests'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor
} as const;

/** Returns whether subviews should be shown for the delta perspective. */
async function canShowDeltaViews(
	perspectiveContext: PerspectiveContext,
	serviceClient: TeamscaleServiceClient,
	hash: NavigationHash,
	view: string
): Promise<boolean> {
	if (hash.getBoolean(DeltaParameters.DELTA_TYPE_PARAMETER_NAME) && isExcludedForSpecItemDelta(view)) {
		return false;
	}
	return hash.getCommitParameter(DeltaParameters.START_COMMIT_PARAMETER_NAME) !== null;
}

/**
 * Returns true if the given view should be excluded in the delta perspective for spec items. The excluded views are
 * only applicable to code deltas, but not to spec item deltas.
 */
function isExcludedForSpecItemDelta(view: string): boolean {
	const excludedViewsForSpecItems = [EDeltaView.TEST_GAP_TREEMAP.name, EDeltaView.TESTS.name];
	return excludedViewsForSpecItems.includes(view);
}
